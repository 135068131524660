import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
	  path: '/',
	  name: 'index',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
	},
  {
    path: '/cliam',
    name: 'cliam',
    component: () => import(/* webpackChunkName: "about" */ '../views/claim.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
